import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  futuresSnapshotState,
  futuresVisibleState,
  oiLatestServerTsMapState,
  unseenAlertCountState,
  userGatekeeperState,
  userIsLoggedInState,
  userSettingsState,
  userSubLevelState,
  workerState,
} from '../states';
import { useCallback, useEffect } from 'react';
import {
  SubLevel,
  FuturesSnapshotData,
  SGSettings,
  PollJsonResponse,
} from '../types';
import poll from '../util/poll';
import useLog from './useLog';
import { userNotificationsState } from '../states/notifications';
import { getUserNotification } from '../util/notifications';
import { DEFAULT_NOTIFICATION_DAYS } from '../config/notifications';
import useAlerts from './alerts/useAlerts';
import { isBloomberg, ONE_MIN_MS } from '../util';
import useUserDetails from './user/useUserDetails';
import useAuth from './auth/useAuth';
import { OH_TOKEN } from '../config/user';

const POLL_UPDATE_INTERVAL = 10_000; // 10 secs
const REFRESH_INTERVAL = 30 * ONE_MIN_MS;

export const usePollUpdate = () => {
  const setAlertCount = useSetRecoilState(unseenAlertCountState);
  const userLevel = useRecoilValue(userSubLevelState);
  const worker = useRecoilValue(workerState);
  const setSnapshots = useSetRecoilState(futuresSnapshotState);
  const setLatestServerTsMap = useSetRecoilState(oiLatestServerTsMapState);
  const futuresVisible = useRecoilValue(futuresVisibleState);
  const loggedIn = useRecoilValue(userIsLoggedInState);
  const setNotifications = useSetRecoilState(userNotificationsState);
  const setSettings = useSetRecoilState(userSettingsState);
  const setUserGKs = useSetRecoilState(userGatekeeperState);

  const { fetchAndSetLatestAlerts } = useAlerts();
  const { initUserDetails } = useUserDetails();
  const { setToken } = useAuth();
  const { logError } = useLog('usePollUpdate');

  const handleNewToken = useCallback(
    ({ json, status }: PollJsonResponse) => {
      if (status < 300) {
        if (json.sgToken != null) {
          if (json.sgToken === OH_TOKEN) {
            debugger;
            throw new Error('Got OH token from response?');
          }
          setToken(json.sgToken);
        }
      } else if (status === 403) {
        // Only clear the token if it's due to a 403 error.  An internal server
        // error (500+) should leave the cached token alone and wait for another
        // refresh attempt.
        setToken(null);
        initUserDetails(undefined); // Sign the user out and navigate to the login
      }
    },
    [initUserDetails, setToken],
  );

  const handleFuturesSnapshot = useCallback(
    (data: any) => {
      if (!Array.isArray(data) || data.length === 0) {
        return;
      }

      setSnapshots((snapshots) => {
        return data.map((d) => {
          if (d.lastPrice != null) {
            return d;
          }
          // if we did not get a lastPrice from the server, use the old price
          const oldSnapshot = snapshots.find((s) => d.sym === s.sym);
          return { ...d, lastPrice: oldSnapshot?.lastPrice };
        }) as FuturesSnapshotData[];
      });
    },
    [setSnapshots],
  );

  const handlePollResponse = useCallback(
    (data: { json: any }) => {
      try {
        if (data?.json == null) {
          return;
        }

        const alertCount = data.json.unreadAlertCount?.count;
        if (alertCount != null) {
          const newCount = parseInt(alertCount);
          setAlertCount((oldCount) => {
            if (oldCount !== newCount) {
              fetchAndSetLatestAlerts();
            }
            return newCount;
          });
        }

        const { futuresSnapshot, gatekeepers, userNotifications } = data.json;
        if (futuresSnapshot != null) {
          handleFuturesSnapshot(futuresSnapshot);
        }
        if (userNotifications != null) {
          setNotifications(userNotifications.map(getUserNotification));
        }
        if (gatekeepers != null) {
          setUserGKs(gatekeepers);
        }

        const settings = data.json.settings as Partial<SGSettings> | undefined;
        if (settings?.lastUpdatedAt != null) {
          setSettings((oldSettings) => {
            const oldLastUpdate = oldSettings?.lastUpdatedAt ?? 0;
            if (oldLastUpdate < settings.lastUpdatedAt!) {
              // do not persist hiro or trace chart settings
              settings.hiro ||= {};
              settings.hiro.chartSettings = oldSettings.hiro?.chartSettings;
              settings.oi = oldSettings.oi;
              return settings;
            }
            return oldSettings;
          });
        }
      } catch (err) {
        logError(err, 'handlePollResponse');
      }
    },
    [
      setAlertCount,
      setLatestServerTsMap,
      setSnapshots,
      setSettings,
      handleFuturesSnapshot,
      fetchAndSetLatestAlerts,
      setNotifications,
      setUserGKs,
      logError,
    ],
  );

  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    const hasAlertsAccess = userLevel >= SubLevel.PRO;
    const features: any = {
      user_notifications: {
        days: DEFAULT_NOTIFICATION_DAYS,
      },
      settings: {},
      gatekeepers: {},
    };

    if (hasAlertsAccess) {
      features.alerts = {};
    }
    if (futuresVisible) {
      features.futures = {};
    }

    return poll(worker, {
      url: `v1/me/pollUpdate?features=${encodeURIComponent(
        JSON.stringify(features),
      )}`,
      interval: POLL_UPDATE_INTERVAL,
      onResponse: handlePollResponse,
    });
  }, [handlePollResponse, userLevel, worker, futuresVisible, loggedIn]);

  useEffect(() => {
    if (!loggedIn || isBloomberg()) {
      return;
    }

    return poll(worker, {
      url: 'v1/me/refresh',
      interval: REFRESH_INTERVAL,
      onResponse: handleNewToken,
    });
  }, [loggedIn, worker]);
};

export default usePollUpdate;
